import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export const Popup = styled.dialog`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  
  .popup {
    display: grid;
    position: relative;
    min-width: 786px;
    width: 768px;
    height: 394px;
    min-height: 394px;
    background-color: white;
    border-radius: 20px;
    grid-template-columns: 1fr 1fr;

      &__image {
        position: absolute;
        width: 372px;
        height: 470px;
        top: -102px;
        left: -44px;
      }

      &__close-icon {
        position: absolute;
        cursor: pointer;
        right: 12px;
        top: 12px;
      }

      &__title {
        font-family: "Citrina", sans-serif;
        font-weight: 700;
        font-size: 46px;
        line-height: 43px;
        margin-bottom: 16px;
      }

      &__description {
        font-family: "Citrina", sans-serif;
        font-weight: 500;
        font-style: italic;
        font-size: 26px;
        display: block;
        margin-bottom: 40px;
      }

      &__orange-text {
        color: ${orange.extra};
      }

      &__content-block {
        grid-column-start: 2;
        margin: 36px 26px 0 0;
      }

      &__link-button {
        font-family: "Citrina", sans-serif;
        font-weight: 700;
        font-size: 20px;
        width: 345px;
        height: 50px;
        border-radius: 10px;
        text-transform: uppercase;
        background-color: ${orange.extra};
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
`
