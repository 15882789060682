import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Close from '@interco/icons/build-v4/bidis/layout/close'

import { Modal } from 'src/components/Modal'
import { orange } from 'src/styles/colors'

import { Popup } from './styles'

const OpenPageModal: React.FC = () => {
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)

  useEffect(() => {
    const hasOpenedBefore = window.sessionStorage.getItem('hasOpenedBefore')
    const timer = setTimeout(() => {
      !hasOpenedBefore && setIsModalOpen(true)
      window.sessionStorage.setItem('hasOpenedBefore', 'true')
    }, 3500)

    return () => clearTimeout(timer)
  }, [ setIsModalOpen ])

  return isModalOpen ? (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <Popup>
        <div className='popup'>
          <img className='popup__image' src='https://central-imagens.bancointer.com.br/images-without-small-versions/popup-cripto-investimentos/image.png' alt='' />
          <Close className='popup__close-icon' height={24} width={24} color={orange.extra} onClick={() => setIsModalOpen(false)} />
          <div className='popup__content-block'>
            <h3 className='popup__title'>Domine o mundo das Criptos do zero e <span className='popup__orange-text'>sem medo!</span></h3>
            <span className='popup__description'>Com aulas <span className='popup__orange-text'>+</span> materiais gratuitos.</span>
            <Link className='popup__link-button' to='/tudo-sobre-criptomoedas/?utm_source=bancointer&utm_medium=Site&utm_campaign=20250314_Investimentos_Criptomoedas_PopupCripto360LPs'>ÚLTIMAS VAGAS</Link>
          </div>
        </div>
      </Popup>
    </Modal>
  ) : null
}

export default OpenPageModal
